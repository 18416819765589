import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import automotiveimage from "../../assests/images/aboutus.png";
import immersion14 from "../../assests/transform/automotive/immersion14.png";
import "../../style/transform.css";
const AIIntegration = () => {
  const [value, setValue] = useState("one");

  const tabsData = [
    {
      img: immersion14,
      value: "two",
      label: "Building your AI SaaS",
      challangeheading:
        "Transform your business idea into a scalable AI-powered Software-as-a-Service solution.",
      chanllangelist: [
        {
          list: "Prompt Engineering, Fine-tuning model or RAG system",
        },
        {
          list: "Scalable cloud architecture design",
        },
        {
          list: "Robust security and compliance measures",
        },
        {
          list: "Continuous monitoring and improvement systems",
        },
        {
          list: "Integration with existing business systems and workflows",
        },
      ],
    },
    {
      img: immersion14,
      value: "three",
      label: "AI Sales Assistant",
      challangeheading:
        "Revolutionize your sales process with our intelligent assistant, seamlessly integrated with digital twin technologies.",
      chanllangelist: [
        {
          list: "Real-time, personalized product information and recommendations",
        },
        {
          list: "Guided product exploration and customization",
        },
        {
          list: "In-depth analysis of customer interactions",
        },
        {
          list: "Enhanced engagement in virtual showrooms and demonstrations",
        },
      ],
    },
    {
      img: immersion14,
      value: "four",
      label: "Computer Vision",
      challangeheading: "Document Verification",
      chanllangelist: [
        {
          list: "Automated identity and document authentication",
        },
        {
          list: "Enhanced security for digital transactions",
        },
      ],
      realityscaleheading: "Real-world Event Detection",
      realityscalelist: [
        {
          list: "Proactive monitoring and response in physical environments",
        },
      ],
      benefitsheading: "Environmental Mapping",
      benefitslist: [
        {
          list: "Detailed 3D mapping",
        },
      ],
    },
    {
      img: immersion14,
      value: "five",
      label: "Intelligent Analytics and Insights",
      realityscaleheading:
        "Gain deep insights from user interactions and system data.",
      realityscalelist: [
        {
          list: "Behavioral analysis in virtual environments",
        },
        {
          list: "Predictive analytics for sales and trends",
        },
        {
          list: "Pattern recognition for customer preferences",
        },
        {
          list: "Data-driven decision support",
        },
      ],
    },
    {
      img: immersion14,
      value: "six",
      label: "Generative AI Content",
      benefitsheading:
        " Create and optimize content across various applications.",
      benefitslist: [
        {
          list: "Automated 3D model and texture generation",
        },
        {
          list: "Realistic virtual environment creation",
        },
        {
          list: "Personalized content based on user preferences",
        },
        {
          list: "Streamlined content creation workflows",
        },
      ],
    },
  ];

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSlideChange = (index) => {
    const selectedTab = tabsData[index];
    setValue(selectedTab.value);
  };

  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          <div className="bg-black mil-dark-bg page_alignment ">
            <div className=" mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="">
                      <ul
                        className="mil-breadcrumbs mil-light"
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">AI/Ml Hub</a>
                        </li>
                      </ul>
                      <h1 className="mil-muted" style={{ color: "#ffd94a" }}>
                        Harness{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          the Power
                        </span>
                        <br /> of{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          Intelligent Solutions
                        </span>
                      </h1>
                      <a
                        href="#"
                        className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      ></a>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        zIndex: 999,
                      }}
                    >
                      {/* automotive defination start */}
                      <Box>
                        <Box
                          sx={{
                            marginBottom: 5,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            At Reality Scale, we leverage cutting-edge AI/ML to
                            transform businesses across industries. Our AI/ML
                            services enhance user experiences, optimize
                            operations, and drive growth through innovative,
                            tailored solutions.
                          </Typography>
                        </Box>

                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "start",
                          }}
                        >
                          <Grid item xs={12} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                RAG Framework
                              </Typography>
                              <Box sx={{ mt: 2 }}>
                                <Typography color={"#fff"} variant="p">
                                  Our Retrieval-Augmented Generation (RAG)
                                  framework creates use case-specific AI
                                  solutions that analyse vast document
                                  repositories, delivering targeted information
                                  to support informed decision-making.
                                </Typography>
                              </Box>{" "}
                              <Box sx={{ mt: 2 }}>
                                <Typography color={"#fff"} variant="p">
                                  Integrating real-time, context-aware data from
                                  diverse sources enhances domain-specific
                                  knowledge and efficiency. This system empowers
                                  users with comprehensive insights for
                                  specialised business applications.
                                </Typography>
                              </Box>
                              <Box>
                                <ul
                                  style={{ marginTop: "10px", color: "#fff" }}
                                >
                                  <li>
                                    Multi-modal data processing (text, images,
                                    video, audio)
                                  </li>
                                  <li>
                                    Real-time access to up-to-date information
                                  </li>
                                  <li>
                                    Robust security with Attribute-Based Access
                                    Control (ABAC)
                                  </li>
                                  <li>
                                    Seamless integration with external agents
                                    and APIs
                                  </li>
                                  <li>
                                    Scalable and flexible to meet diverse
                                    business needs
                                  </li>
                                  <li>
                                    Available as SaaS or self-hosted solution
                                  </li>
                                  <li>
                                    Kaman technology integration for automatic
                                    data source updates
                                  </li>
                                </ul>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "550px",
                                objectFit: "cover",
                              }}
                              alt="RAG Framework"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive defination ends */}{" "}
                      {/* slider content code start */}
                      <Box
                        sx={{
                          my: 7,
                        }}
                      >
                        <Typography variant="h2" fontWeight={500} fontSize={35}>
                          Industry-Specific Solutions
                        </Typography>
                        <Box sx={{ width: "100%", marginTop: "2rem" }}>
                          {/* Tabs Section */}
                          <Tabs
                            value={value}
                            onChange={handleTabChange}
                            TabIndicatorProps={{ style: { display: "none" } }}
                          >
                            {tabsData.map((tab) => (
                              <Tab
                                key={tab.value}
                                value={tab.value}
                                label={tab.label}
                                wrapped
                                sx={{
                                  color:
                                    value === tab.value
                                      ? "#ffd94a !important"
                                      : "#fff",
                                  borderBottom:
                                    value === tab.value
                                      ? "2px solid #ffd94a"
                                      : "2px solid transparent",
                                }}
                              />
                            ))}
                          </Tabs>

                          <Carousel
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3000}
                            selectedItem={tabsData.findIndex(
                              (tab) => tab.value === value
                            )}
                            onChange={handleSlideChange}
                          >
                            {tabsData.map((tab) => (
                              <Box
                                key={tab.value}
                                sx={{
                                  backgroundColor: "#000",
                                  color: "#fff",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  flexDirection: "column",
                                }}
                              >
                                <img src={tab.img} height={"300px"} />

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    flexDirection: "row",
                                    padding: "10px 45px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        mt: 2,
                                      }}
                                    >
                                      <Typography
                                        variant="h1"
                                        fontSize={30}
                                        fontWeight={600}
                                        sx={{ textAlign: "left" }}
                                      >
                                        {tab.label}
                                      </Typography>
                                    </Box>
                                    <Box sx={{ marginTop: "15px" }}>
                                      <Typography sx={{ textAlign: "left" }}>
                                        {tab.challangeheading}
                                      </Typography>
                                      <Box sx={{ marginTop: "10px" }}>
                                        <ul style={{ textAlign: "left" }}>
                                          {tab.chanllangelist?.map(
                                            (val, id) => (
                                              <li key={id}>
                                                <Typography
                                                  sx={{
                                                    textAlign: "left",
                                                    marginBottom: "8px",
                                                  }}
                                                >
                                                  {val.list}
                                                </Typography>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </Box>
                                    </Box>

                                    <Box sx={{ marginTop: "15px" }}>
                                      <Typography sx={{ textAlign: "left" }}>
                                        {tab.realityscaleheading}
                                      </Typography>
                                      <Box sx={{ marginTop: "10px" }}>
                                        <ul style={{ textAlign: "left" }}>
                                          {tab.realityscalelist?.map(
                                            (val, id) => (
                                              <li key={id}>
                                                <Typography
                                                  sx={{
                                                    textAlign: "left",
                                                    marginBottom: "8px",
                                                  }}
                                                >
                                                  {val.list}
                                                </Typography>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </Box>
                                    </Box>

                                    <Box sx={{ marginTop: "15px" }}>
                                      <Typography sx={{ textAlign: "left" }}>
                                        {tab && tab.benefitsheading}
                                      </Typography>
                                      <Box sx={{ marginTop: "10px" }}>
                                        <ul style={{ textAlign: "left" }}>
                                          {tab.benefitslist?.map((val, id) => (
                                            <li key={id}>
                                              <Typography
                                                sx={{
                                                  textAlign: "left",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                {val.list}
                                              </Typography>
                                            </li>
                                          ))}
                                        </ul>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Carousel>
                        </Box>
                      </Box>
                      {/* slider content code ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "400px",
                                objectFit: "cover",
                              }}
                              alt="RAG Framework"
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={6}>
                            <Box>
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "start",
                                }}
                              >
                                <Typography
                                  variant="h2"
                                  fontWeight={500}
                                  fontSize={35}
                                >
                                  Additional AI/ML Services:
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  mt: 3,
                                }}
                              >
                                <ol
                                  style={{
                                    color: "#fff",
                                    marginTop: "12px",
                                  }}
                                >
                                  <li>
                                    Integrating OpenAI's GPT-4/ChatGPT into your
                                    business flow
                                  </li>
                                  <li className="mt-2">
                                    Designing a prompt engineering solution to
                                    decrease some of the workload in your
                                    business
                                  </li>
                                  <li className="mt-2">
                                    Finetune an LLM model specific to your niche
                                  </li>
                                  <li className="mt-2">
                                    Voice recognition and synthesis
                                  </li>
                                  <li className="mt-2">
                                    AI/ML-assisted virtual space layout
                                    optimisation
                                  </li>
                                  <li className="mt-2">
                                    Gesture recognition for intuitive VR
                                    interactions
                                  </li>
                                </ol>
                              </Box>{" "}
                              <Box
                                sx={{
                                  mt: 1,
                                }}
                              >
                                <Typography color={"#fff"} variant="p">
                                  Partner with Reality Scale to transform your
                                  automotive business. Contact us today to
                                  discover how we can elevate your brand and
                                  drive success in the evolving automotive
                                  landscape.
                                </Typography>
                              </Box>{" "}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}
                      <Box
                        sx={{
                          mt: 3,
                        }}
                      >
                        <Typography variant="p" color={"#fff"}>
                          Our team of AI/ML experts is ready to develop tailored
                          solutions that address your specific industry
                          challenges and use cases. Partner with Reality Scale
                          to unlock your business's full potential with AI/ML.
                        </Typography>
                        <Box
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            Contact us today to explore how our AI/ML services
                            can drive innovation and growth for your
                            organisation.
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default AIIntegration;
