import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "../components/footer";
import Mouse from "../components/mouse";
import Navbar from "../components/navbar";
import Progressbar from "../components/progressbar";
import Contactus from "../views/contact";
import DEMOREALITY from "../views/demoreality";
import FeatureDetails from "../views/featuredetails";
import FirstDisneyProject from "../views/featurelistDisneyProject";
import Home from "../views/home";
import Privacy from "../views/privacy";
import FirstBlog from "../views/realityscaleblogs/firstblog";
import SecondBlog from "../views/realityscaleblogs/secondblog";
import Member from "../views/team_member";
import TeamDetails from "../views/TeamDetails";
import Terms from "../views/terms";
import AIIntegration from "../views/transform/aiintegration";
import Automotive from "../views/transform/automative";
import Framework from "../views/transform/framework";
import Frontier from "../views/transform/frontier";
import Immersion from "../views/transform/immersion";
import RealEstate from "../views/transform/realEstate";
import RealityScaleBlog from "../views/blogPage";
import Portfolio from "../views/product";
import Portfoliodetails from "../views/productdetails";
import NotFound from "../views/pagenotfound";
import UnseenBlog from "../views/realityscaleblogs/thirdblog";

const Router = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/demoreality" && <Navbar />}
      <Mouse />
      <Progressbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Member />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route
          path="/portfoliodetails/:pageurl"
          element={<Portfoliodetails />}
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/demoreality" element={<DEMOREALITY />} />
        <Route path="/features-details/:heading" element={<FeatureDetails />} />
        <Route
          caseSensitive
          exact
          path="/blog"
          element={<RealityScaleBlog />}
        />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/FirstDisneyProject" element={<FirstDisneyProject />} />
        <Route path="/transform/ai-ml-hub" element={<AIIntegration />} />
        <Route path="/transform/automotive" element={<Automotive />} />
        <Route
          path="/transform/realityscale-framework"
          element={<Framework />}
        />
        <Route path="/transform/web3-frontier" element={<Frontier />} />
        <Route path="/transform/xr-immersion" element={<Immersion />} />
        <Route path="/transform/realestate-solution" element={<RealEstate />} />
        <Route path="/blog/marketing-evoltuion" element={<FirstBlog />} />
        <Route path="/blog/exploringxr" element={<SecondBlog />} />
        <Route path="/blog/Selling_the_unseen" element={<UnseenBlog />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      {location.pathname !== "/demoreality" && <Footer />}
    </>
  );
};

export default Router;
