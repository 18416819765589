import Aos from "aos";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import data from "../../assests/data/data";
import image1 from "../../assests/images/landing/1.9f88f939.webp";
import ArrowSVG from "../arrow";
import "./index.css";

const Business = () => {
  let navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth > 768) {
      Aos.init();
    }
  }, []);

  const handleRoute = (val) => {
    navigate(`${val.url}`, {
      state: { val },
    });
  };
  return (
    <div className=" bg-black uniquesection_height">
      <div className="container mil-p-120-30 section_height">
        <div className="mil-complex-text justify-content-center mil-up mil-mb-15">
          <span className="mil-text-image">
            <img src={image1} alt="team" />
          </span>
          <h2
            className="mil-h1 mil-muted mil-center uniidea"
            style={{ color: "#ffd94a" }}
          >
            Unique <span className="mil-thin text-white">Ideas</span>
          </h2>
        </div>
        <div className="mil-complex-text justify-content-center mil-up">
          <h2
            className="mil-h1 mil-muted mil-center"
            style={{ color: "#ffd94a" }}
          >
            For Your <span className="mil-thin text-white">Business.</span>
          </h2>
          <div className="mil-services-button mil-button mil-arrow-place">
            {/* <Link to="/blog" className="text-black p-0"> */}
            <a href="/blog" className="text-black p-0">
              <span className="text-center">Show more</span>
              <ArrowSVG className="mil-arrow" />
            </a>
            {/* </Link> */}
          </div>
        </div>

        <div className="row mil-services-grid  mt-5">
          {data.uniquedata.map((val, index) => (
            <div
              key={index}
              className="col-md-6 col-lg-3 mil-services-grid-item p-0"
            >
              <div
                className="mil-service-card-sm mil-up"
                data-aos="fade-up"
                data-aos-offset="20"
                data-aos-delay="50"
                data-aos-duration="800"
                data-aos-easing="ease-in-out"
                data-aos-mirror="false"
                data-aos-once="true"
                data-aos-anchor-placement="top"
              >
                <h5 className="mil-muteded mil-mb-30 text-white">{val.head}</h5>
                <p className="mil-light-softer mil-mb-30 text-white fw-100">
                  {val.para}
                </p>
                <div className="" onClick={() => handleRoute(val)}>
                  <a href={val.url}>
                    <span
                      style={{
                        marginRight: "10px",
                        color: "#d3d3d3",
                        fontSize: "small",
                      }}
                    >
                      Read More
                    </span>
                    <img
                      src={val.img}
                      className="mil-button mil-icon-button-sm mil-arrow-place"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Business;
