import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import ArrowSVG from "../../components/arrow";
const HomePageBannerSection = () => {
  return (
    <div className="bg-black">
      <div className="mil-animation-frame mobile_animation">
        <div
          className="mil-animation mil-position-1 mil-scale"
          data-value-1="6"
          data-value-2="1.3"
          style={{
            transform: "translate3d(0px, 0px, 0px) scale(2.5044, 2.5044)",
          }}
        >
          <div className="mil-dodecahedron">
            {[...Array(12)].map((_, index) => (
              <div key={index} className="mil-pentagon">
                {[...Array(5)].map((_, index) => (
                  <div key={index}></div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div
          className="mil-animation mil-position-2 mil-scale"
          data-value-1="4"
          data-value-2="1"
          style={{
            transform: "translate3d(0px, 0px, 0px) scale(1.2836, 1.2836)",
          }}
        >
          <div className="mil-dodecahedron">
            {[...Array(12)].map((_, index) => (
              <div key={index} className="mil-pentagon">
                {[...Array(5)].map((_, index) => (
                  <div key={index}></div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <section className="hero-section container">
        <div className="hero-overlay">
          <div className="hero-content">
            <h1 className="mil-mutede mil-mb-30 section_heading">
              <span
                style={{
                  color: "#ffd94a",
                }}
              >
                Your{" "}
              </span>
              <span className="mil-thins" style={{ fontWeight: "100px" }}>
                Digital
              </span>{" "}
              <br />
              <span
                style={{
                  color: "#ffd94a",
                }}
              >
                Innovation
              </span>{" "}
              <span className="mil-thins">Partner</span>
            </h1>
            <p>
              Step into tomorrow's world, today – with reinventive and
              data-driven solutions that define business interactions and
              customer experience. Transform your venture and vision.
            </p>

            <div className="screen_display">
              <Link
                to="/contact"
                className="mil-link mil-muted mil-arrow-place contact_usbutton "
              >
                <span> Contact Us</span>
                <ArrowSVG className="mil-arrow" fill="#000000" />
              </Link>
              <Link
                to="/demoreality"
                className="mil-button mil-arrow-place mil-btn-space mx-3 demo_button"
              >
                <span>Demo Reality</span>
                <ArrowSVG
                  className="mil-arrow demoarrow_button"
                  fill="#000000"
                />
              </Link>
            </div>
            <div className="phone_button">
              <div className="d-flex ">
                <div className="text-white">
                  {" "}
                  <Link
                    to="/contact"
                    className="mil-link mil-muted mil-arrow-place contact_usbutton "
                  >
                    <span> Contact Us</span>
                    <ArrowSVG className="mil-arrow" fill="#000000" />
                  </Link>
                </div>
                <div className="text-white  dmo_button">
                  {" "}
                  <Link
                    to="/demoreality"
                    className="mil-link mil-muted mil-arrow-place dmo_button "
                  >
                    <span>Demo Reality</span>
                    <ArrowSVG className="mil-arrow" fill="#000000" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-video">
            <video autoPlay loop muted>
              <source
                src="https://reality-scale-buck.s3.amazonaws.com/Website+V02.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePageBannerSection;
