import { Landscape } from "@mui/icons-material";
import chart from "../../assests/images/blogchart.webp";
import arrow from "../images/svg/arrow.png";
let unique = {
  head1: "Pixel Perfect Marketing Evolution",
  para1:
    "The article discusses the challenges of digital marketing and how Reality Scale's tech offers a solution.",
  img1: arrow,
  chart: chart,

  head2: "XR Revolution in Manufacturing and Beyond",
  para2:
    "Description - XR revolutionizes manufacturing, sparking industry-wide changes. We examines its impact across sectors.",
  img2: arrow,

  head3: "Selling the unseen: A dive into property replica",
  para3:
    "Addressing Digital twin market growth and demand through aN individual's perspective",
  img3: arrow,

  head4: "Branding and Identity Design",
  para4:
    "Our creative agency is a team of professionals focused on helping your brand grow.",
  img4: arrow,
};
export default unique;
